import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ServiceStatesMapLargeSpanish from "../../components/service-states/service-states-map-spanish-lg";
import ServiceStatesMapSmallSpanish from "../../components/service-states/service-states-map-spanish-sm";
import Hero from "../../components/hero/hero";
import Button from "../../components/custom-widgets/button";

import getHeroImgVariables from "../../helpers/getHeroImgVariables";

const WorkplaceBanking = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(relativePath: { eq: "hero/workperks/hero-workperks-01-2500.jpg" }) {
        ...heroImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/workperks/hero-workperks-01-1920.jpg" }) {
        ...heroImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/workperks/hero-workperks-01-1200.jpg" }) {
        ...heroImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/workperks/hero-workperks-01-992.jpg" }) {
        ...heroImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/workperks/hero-workperks-01-768.jpg" }) {
        ...heroImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/workperks/hero-workperks-01-600.jpg" }) {
        ...heroImageFragmentXS
      }
    }
  `);
  const title = "Work Perks";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/work-perks"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Work Perks"
    }
  ];

  const heroData = {
    id: "es-work-perks-hero",
    ...getHeroImgVariables(imgData, ["xs", "sm", "md", "lg", "xl", "xxl"])
  };

  const compareAccountsCtaData = {
    id: "compare-accounts-cta",
    containerClass: "my-3",
    type: "link",
    class: "btn-primary",
    text: "Comparar Cuentas",
    url: "/es/banca-personal/cuenta-de-cheques",
    showIcon: false
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Hero {...heroData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <StaticImage
          src="../../images/wafd-work-perks-275x89.png"
          alt="WaFd Work Perks Logo"
          quality="100"
          placeholder="blurred"
        />
        <h3>Su empresa se ha inscrito en WaFd Work Perks... ¡y usted obtiene los beneficios!</h3>
        <p>Es hora de esperar más de su Banco.</p>
        <p>
          Ahora puede realizar operaciones bancarias de manera más inteligente con protección y cobertura para usted y
          su familia con nuestras Rewards, Premium Rewards, o Interest Checking, o elija la Cuenta Free Checking sin
          saldo mínimo ni cargos mensuales. De cualquier manera, obtienes acceso a extras con Work Perks.
        </p>
        <h3>Todas las cuentas Work Perks incluyen:</h3>
        <ul>
          <li>Banca en línea y móvil con pago de facturas y depósito móvil</li>
          <li>Primera caja de cheques gratuita</li>
          <li>
            Hasta $5,000 de descuento en tarifas de originación de préstamos hipotecarios, de refinanciamiento o de
            construcción personalizada**
          </li>
          <li>Herramientas gratuitas de presupuesto y finanzas personales</li>
          <li>
            Tarjeta de débito con acceso gratuito a miles cajeros automáticos de MoneyPass<sup>&trade;</sup>
          </li>
          <li>
            Pagos de persona a persona con Zelle<sup>&reg;</sup>
          </li>
        </ul>
        <h3>
          Elija la cuenta Rewards, Premium Rewards, o Interest Checking para recibir estos beneficios adicionales:
        </h3>
        <ul>
          <li>5c/gal de descuento con gasolina</li>
          <li>Descuentos de compras</li>
          <li>Descuentos para viajes y esparcimiento</li>
          <li>Seguro de telefono movil</li>
          <li>Servicios de monitoreo de crédito y resolución de robo de identidad</li>
          <li>Protección de comprador/garantía extendida</li>
          <li>Y mas!</li>
        </ul>
        <Button {...compareAccountsCtaData} />
        <p>
          Disfrute de un servicio completo para su cuenta con servicio 24/7 de nuestro Centro de Atención al Cliente
        </p>
        <p className="font-weight-bold">
          <em>Traiga su folleto o mencione este sitio en el momento de la apertura de cuenta.</em>
        </p>
        <p className="mb-0">
          Todas las cuentas corrientes requieren un depósito inicial de $25 para abrirse. Algunos beneficios de la
          cuenta requieren registro y activación.
        </p>
      </section>
      <section className="container pt-0">
        <h2>Encuentre una sucursal cerca de usted</h2>
        <div className="d-none d-md-block">
          <ServiceStatesMapLargeSpanish />
        </div>
        <div className="d-block d-md-none">
          <ServiceStatesMapSmallSpanish />
        </div>
      </section>
      <section className="container pt-0">
        <h3>Tarifa:</h3>
        <p>
          *La cuenta de Free Checking no tiene un requisito de saldo mínimo ni cargos mensuales. Se pueden aplicar
          cargos por sobregiro/artículo devuelto.
        </p>
        <p>
          La cuenta de cheques Rewards cuesta $6 por mes y la cuenta de cheques Premium Rewards cuesta $9 por mes sin
          requisitos de saldo mínimo.
        </p>
        <p>
          El interés en la cuenta corriente es de $12 por mes; el cargo no se aplica con un saldo diario promedio de
          $5,000 o con $50,000 en cuentas elegibles. Se ganan intereses sobre todos los saldos.
        </p>
        <hr />
        <p className="text-muted">
          **Hasta $5,000 de descuento en las tarifas de originación del préstamo, incluido el informe de crédito, la
          certificación de inundaciones y las tarifas de control de impuestos. Esta oferta está disponible para
          transacciones de compra y refinanciamiento, de residencia principal y vivienda ocupada por el propietario, y
          préstamos para construcción personalizados. $5,000 de descuento en la tarifa de originación del préstamo
          requiere el pago automático de la hipoteca a través de EZ-Pay desde una cuenta corriente de WAFD, depósito
          directo de nómina, uno o más pagos de facturas en línea y reservas mensuales. La financiación de segundas
          residencias, propiedades de inversión y préstamos para lotes no son elegibles para descuentos. Todos los
          préstamos están sujetos a aprobación de crédito.
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default WorkplaceBanking;
